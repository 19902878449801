import React, { useState, useContext } from "react";
import { useFinalQuote } from "hooks/Quotes/useFinalQuote";
import DataProvider from "Context/DataContext";
import { useTailoredQuote } from "hooks/Forms/useTailoredQuote";
import Loader from "components/loader";
import SaveQuotePopUp from "./SaveQuotePopUp";
import { useForm } from "react-hook-form";
import ThankYouPopup from "components/popup/thankYouPopup";
import QuoteCard from "../firstComp/QuoteCard";
import formCommonPostData from "utils/form-common-post-data";
import { useLocation } from "@reach/router";
import { errorMessageEmail, patternEmail } from "utils/form-validation";
import Gurantee from "assets/gurantee.svg";
import Pro from "assets/pro.svg";

import { Tooltip } from "react-tooltip";
import { title } from "process";

function SecondStep({
  setStep,
  setSelected,
  setOpen,
  translatevalue,
  showGridSection,
}) {
  const { quoteId, stepsData, setStepsData, setThirdStepData } =
    useContext(DataProvider);
  const location = useLocation();
  const [tailoredBtn, setTailoredBtn] = useState("");
  const handleTailoredBtn = (e) => {
    setTailoredBtn(e.target.value);
  };

  const [thankOpen, setThankOpen] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const { status, mutateAsync } = useFinalQuote();
  const [serviceType, setServiceType] = useState(1);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const SERVICE_TYPE_BEST_PRICE = 1;
  const SERVICE_TYPE_BEST_TIME = 2;

  const OnSubmit = (params) => {
    const postData2 = {
      service_type: params,
      quote_id: quoteId,
      pagelink: location.href,
    };

    const postData = { ...postData2, ...formCommonPostData() };
    mutateAsync(postData).then((data) => {
      setThirdStepData(data);
      setStepsData(data);
    });
    localStorage.setItem("step", 2);
    setStep("2");
    setSelected("2");
  };

  const { status: Status, mutateAsync: MutateAsyncronous } = useTailoredQuote();

  const SubmitSubscribe = (data) => {
    const postData1 = {
      email: data.email,
      quote_id: quoteId,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    MutateAsyncronous(postData).then((response) => {
      reset();
      setThankOpen(true);
    });
  };
  const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    <>
      <div
        // ref={secondStepRef}
        className="flex items-center justify-center w-full  "
      >
        <div className="mainContainer   w-full  flex-col  font-secondary   ">
          <div className="flex-col flex items-center justify-center w-full ">
            {showGridSection && (
              <div
                className={`${
                  stepsData?.data?.quote_items?.length > 3
                    ? "textDiv flex justify-center py-6 text-center w-full items-start  md:flex-wrap flex-wrap"
                    : "textDiv flex justify-center py-6 text-center w-full items-start  md:flex-nowrap flex-wrap"
                } `}
                // className="textDiv flex justify-center md:flex-nowrap flex-wrap py-6 text-center w-full items-start"
              >
                <p className="text-lg leading-8 text-center font-opensans flex flex-wrap md:mb-0 mb-3">
                  {" "}
                  <span className="font-bold mr-1">Language Pair : </span>{" "}
                  {stepsData?.data?.quote?.source_language ? (
                    stepsData?.data?.quote?.source_language
                  ) : (
                    <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-28 ml-2"></div>
                  )}
                  -
                  {stepsData?.data?.quote_items?.map(({ target_language }) => (
                    <span>{target_language},</span>
                  ))}{" "}
                </p>

                <p className=" text-center leading-8 font-opensans ml-2 lg:ml-4 text-lg flex items-center justify-between">
                  <span className="font-bold whitespace-nowrap mr-1">
                    Word Count :
                  </span>{" "}
                  {stepsData?.data?.quote?.total_words ? (
                    stepsData?.data?.quote?.total_words
                  ) : (
                    <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-28 ml-2"></div>
                  )}
                </p>

                <p className="text-lg leading-8 text-center font-opensans flex flex-wrap  ml-2 lg:ml-4 md:mb-0 mb-3">
                  <span className="font-bold text-[#000000] mr-1">
                    File/s Uploaded :
                  </span>

                  {stepsData?.data?.quote_documents?.length
                    ? stepsData?.data?.quote_documents
                        ?.slice(0, 1)
                        .map((value, index) => (
                          <span
                            key={index}
                            className="w-[130px] text-[#FF7B16] text-ellipsis truncate"
                            title={value.originalName}
                          >
                            {value.originalName}
                          </span>
                        ))
                    : "No file "}
                  {stepsData?.data?.quote_documents?.length > 1 && (
                    <span className="">
                      +{stepsData?.data?.quote_documents?.length - 1} more
                    </span>
                  )}
                </p>
              </div>
            )}

            <SaveQuotePopUp
              open={popUpOpen}
              setsOpen={setPopUpOpen}
              setOpen={setOpen}
              serviceType={serviceType}
            />
            {!stepsData?.data?.quote?.is_special && (
              <>
                <div className="cardsDiv lg:flex gap-2 w-full gap-y-7 justify-between   py-6 ">
                  <div className="cardOne w-full lg:max:w-xl md:relative  border border-[#9AB4DB] rounded-xl  text-center  p-4 md:p-5  bg-white lg:mb-0 mb-5">
                    <div className="flex justify-between gap-2 items-center">
                      <p className="text-lg  font-opensans text-[#00173A] leading-5  font-semibold ">
                        BEST PRICE
                      </p>

                      <p className=" text-[26px] flex gap-2 items-center  font-opensans leading-6 font-bold">
                        {stepsData?.data?.quote?.best_price_amount
                          ? stepsData?.data?.quote?.best_price_amount
                          : "$0"}
                      </p>
                    </div>
                    {stepsData?.data?.quote?.best_price_tax_amount && (
                      <Tooltip
                        id="best_price_amount"
                        place="right"
                        multiline={true}
                        className="z-50"
                      />
                    )}

                    <div className="my-5 md:flex  gap-4 items-center ">
                      <div className="flex items-center gap-2 font-opensans text-[#626262] font-normal text-sm">
                        <img src={Gurantee} alt="/" className="w-[13.6px]" />
                        <p className="w-[125px]">1 year guarantee</p>
                      </div>
                      <div className="flex gap-2 font-opensans text-[#626262] font-normal text-sm">
                        <img src={Pro} alt="/" className="w-[13.6px]" />
                        <p className="w-[174px]">Professional translators</p>
                      </div>
                    </div>
                    <div className="border border-[#0000001a] w-full "></div>
                    <div className="mt-[14px] mb-5 font-opensans text-base leading-4 font-extrabold">
                      <div className="flex justify-between gap-4">
                        <span className="text-base text-[#626262]  leading-4 font-medium">
                          Total words
                        </span>
                        <p className="text-[#323232] font-bold">
                          {stepsData?.data?.quote?.number_of_words
                            ? stepsData?.data?.quote?.number_of_words
                            : "--"}{" "}
                          <span className="text-base text-[#626262] leading-4 font-medium">
                            words
                          </span>
                        </p>
                      </div>
                      <p className="flex justify-between leading-4 gap-4 py-[10px]">
                        <span className="text-base text-[#626262] font-medium">
                          Price per word
                        </span>
                        <span className="text-base font-bold text-[#323232]">
                          {" "}
                          {stepsData?.data?.quote?.price_per_word_best_price
                            ? stepsData?.data?.quote?.price_per_word_best_price
                            : 0}
                        </span>
                      </p>
                      <p className="flex justify-between gap-4 leading-4 items-center">
                        <span className="text-base text-[#626262] font-medium">
                          Max delivery time
                        </span>
                        <span className="text-sm font-bold text-[#323232]">
                          {stepsData?.data?.quote
                            ?.total_best_price_duration_in_words
                            ? stepsData?.data?.quote
                                ?.total_best_price_duration_in_words
                            : "0hrs"}
                        </span>
                      </p>
                    </div>
                    {showGridSection ? (
                      <div className="">
                        {/* <input type="hidden" id="zc_gad" name="zc_gad" value="" /> */}

                        <button
                          className="best_price bg-orange text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center"
                          onClick={() => OnSubmit(SERVICE_TYPE_BEST_PRICE)}
                        >
                          Continue
                        </button>
                        <p
                          className="underline max-w-max text-center pt-2 mx-auto hover:text-orange font-arial text-sm cursor-pointer"
                          onClick={() => {
                            setServiceType(SERVICE_TYPE_BEST_PRICE);
                            setPopUpOpen(true);
                          }}
                        >
                          SAVE QUOTE
                        </p>
                      </div>
                    ) : (
                      <div className="">
                        <button className="best_price bg-[#ebe9e6] text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center">
                          Continue
                        </button>

                        <p className="text-[#012458] text-center pt-2 mx-auto font-opensans text-sm font-normal">
                          SAVE QUOTE
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="cardOne w-full lg:max:w-xl md:relative  border border-[#9AB4DB] rounded-xl  text-center p-4   md:p-5  bg-white lg:mb-0 mb-5">
                    <div className="flex justify-between gap-1">
                      <p className="text-lg w-[138px] font-opensans text-[#00173A] leading-5  font-semibold ">
                        FAST DELIVERY
                      </p>
                      <span className="bg-[#3a7aff] p-1 text-white rounded-lg text-[10px] font-semibold">
                        RECOMMENDED
                      </span>
                      <p className="text-[26px] flex gap-2 items-center  font-opensans leading-6 font-bold">
                        {stepsData?.data?.quote?.best_time_amount
                          ? stepsData?.data?.quote?.best_time_amount
                          : "$0"}
                      </p>
                    </div>
                    {stepsData?.data?.quote?.best_time_tax_amount && (
                      <Tooltip
                        id="best_time_tax_amount"
                        place="right"
                        multiline={true}
                        className="z-50"
                      />
                    )}
                    <div className="my-5 md:flex items-center  gap-4">
                      <div className="flex items-center gap-2 font-opensans text-[#626262] font-normal text-sm">
                        <img src={Gurantee} alt="/" className="w-[13.6px]" />
                        <p className="w-[125px]">1 year guarantee</p>
                      </div>
                      <div className="flex gap-2 font-opensans text-[#626262] font-normal text-sm">
                        <img src={Pro} alt="/" className="w-[13.6px]" />
                        <p className="w-[174px]">Professional translators</p>
                      </div>
                    </div>
                    <div className="border border-[#0000001a] w-full "></div>
                    <div className="mt-[14px] mb-5 text-[#626262] font-opensans text-base leading-4 font-extrabold">
                      <div className="flex justify-between gap-4">
                        <span className="text-base leading-4 font-medium">
                          Total words
                        </span>
                        <p className="text-[#323232] font-bold">
                          {stepsData?.data?.quote?.number_of_words
                            ? stepsData?.data?.quote?.number_of_words
                            : "--"}{" "}
                          <span className="text-base text-[#626262] leading-4 font-medium">
                            words
                          </span>
                        </p>
                      </div>
                      <p className="flex justify-between leading-4 gap-4 py-[10px]">
                        <span className="text-base text-[#626262] font-medium">
                          Price per word
                        </span>
                        <span className="text-base font-bold text-[#323232]">
                          {" "}
                          {stepsData?.data?.quote?.price_per_word_best_time
                            ? stepsData?.data?.quote?.price_per_word_best_time
                            : 0}
                        </span>
                      </p>
                      <p className="flex justify-between items-center leading-4 gap-4">
                        <span className="text-base text-[#626262] font-medium">
                          Max delivery time
                        </span>
                        <span className="text-sm  font-bold text-[#323232]">
                          {stepsData?.data?.quote
                            ?.total_best_time_duration_in_words
                            ? stepsData?.data?.quote
                                ?.total_best_time_duration_in_words
                            : "0hrs"}
                        </span>
                      </p>
                    </div>
                    {showGridSection ? (
                      <div className="">
                        <button
                          className="fast_delivery bg-orange text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center"
                          onClick={() => OnSubmit(SERVICE_TYPE_BEST_TIME)}
                        >
                          Continue
                        </button>
                        <p
                          className="underline max-w-max text-center pt-2 mx-auto hover:text-orange font-arial text-sm cursor-pointer"
                          onClick={() => {
                            setServiceType(SERVICE_TYPE_BEST_TIME);
                            setPopUpOpen(true);
                          }}
                        >
                          SAVE QUOTE
                        </p>
                      </div>
                    ) : (
                      <div className="">
                        <button className="best_price bg-[#ebe9e6] text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center">
                          Continue
                        </button>

                        <p className="text-[#012458] text-center pt-2 mx-auto font-opensans text-sm font-normal">
                          SAVE QUOTE
                        </p>
                      </div>
                    )}
                  </div>
                  <form
                    className="cardThree w-full lg:max:w-xl  md:relative  border border-[#9AB4DB] rounded-xl  text-center   p-4 md:p-5  bg-white lg:mb-0 mb-5"
                    onSubmit={handleSubmit(SubmitSubscribe)}
                  >
                    <p className="text-lg  font-opensans text-start text-[#00173A] leading-5  font-semibold ">
                    GET A QUICK TAILORED QUOTE
                    </p>
                    <p className="pt-5 text-base text-start font-opensans text-[#626262] leading-6">
                      A Tomedes expert will analyze your request and get back to
                      you in a short time
                    </p>
                    <div className="my-6">
                      <input
                        className={`h-[52px]   w-full text-quotetext-200 ${"border border-quotetext-100"}  rounded-lg px-5`}
                        type="email"
                        name="email"
                        placeholder="Enter your email address here"
                        required
                        {...register("email", {
                          required: true,
                          pattern: patternEmail,
                        })}
                        onChange={handleTailoredBtn}
                        value={tailoredBtn}
                      />
                      <p className="text-left text-xs font-arial text-quotetext-200 mt-[2px] mb-1.5">
                        *will only be used to contact you once
                      </p>

                      {errors.email && (
                        <span className="text-xs font-opensans font-normal text-left w-full text-red">
                          Please enter a valid email.
                        </span>
                      )}
                    </div>
                    <div className="">
                      {tailoredBtn.length > 0 ? (
                        <button
                          type="submit"
                          className={`tailored_quote bg-orange text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center`}
                        >
                          {Status === "loading" ? <Loader /> : "Continue"}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className={`tailored_quote bg-orange text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center`}
                        >
                          {Status === "loading" ? <Loader /> : "Continue"}
                        </button>
                      )}

                      <p className="invisible underline max-w-max text-center pt-2 mx-auto hover:text-orange font-arial text-sm cursor-pointer">
                        SAVE QUOTE
                      </p>
                    </div>
                  </form>
                </div>
              </>
            )}

            {stepsData?.data?.quote?.is_special === 1 && (
              <>
                <div className="cardsDiv grid grid-cols-1 place-items-center md:w-11/12 w-full gap-y-7   py-6 ">
                  <form
                    className="cardspecial max-w-[396px] border border-t border-b border-textgray text-center xl:px-8 xl:py-10 md:px-6 md:py-7 px-5 py-6  bg-white md:mb-0 mb-5"
                    onSubmit={handleSubmit(SubmitSubscribe)}
                  >
                    <p className="text-3xl font-arial pb-4 font-semibold">
                      Special Offer
                    </p>
                    <p className="pb-6 leading-[30px] font-arail text-xl mt-3">
                      We are now offering a special offer on this translation.
                      <br />A Tomedes expert will analyze your request and get
                      back to you in a short time
                    </p>
                    <input
                      className="h-[52px] w-full text-quotetext-200 border border-quotetext-100 rounded-lg px-5"
                      type="email"
                      name="email"
                      placeholder="Enter your email address here"
                      required
                      {...register("email", {
                        required: true,
                        pattern: patternEmail,
                      })}
                    />
                    <input type="hidden" id="zc_gad" name="zc_gad" value="" />

                    <p className="text-left text-xs font-arial text-quotetext-200 mt-[2px] mb-1.5">
                      *will only be used to contact you once
                    </p>
                    {errors.email && (
                      <span className="text-xs font-opensans font-normal text-left w-full text-red">
                        {errorMessageEmail}
                      </span>
                    )}

                    <button
                      type="submit"
                      className={`
        bg-orange text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center`}
                    >
                      {Status === "loading" ? <Loader /> : "Continue"}
                    </button>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>

        <ThankYouPopup
          open={thankOpen}
          setOpen={setThankOpen}
          setOpenPopUp={setOpen}
        />
      </div>
    </>
  );
}

export default SecondStep;
