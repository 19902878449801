import React, { useState, useContext, useEffect } from "react";
import { useFinalQuote } from "hooks/Quotes/useFinalQuote";
import DataProvider from "Context/DataContext";
import { useTailoredQuote } from "hooks/Forms/useTailoredQuote";
import Loader from "components/loader";
import { useForm } from "react-hook-form";
import formCommonPostData from "utils/form-common-post-data";
import { useLocation } from "@reach/router";
import { errorMessageEmail, patternEmail } from "utils/form-validation";
import Gurantee from "assets/gurantee.svg";
import Pro from "assets/pro.svg";
import ThankYouPopup from "components/popup/thankYouPopup";

function PricingSection({
  setStep,
  setSelected,
  setOpen,
  transtalteFromInput,
  storeValue,
  setErrorOne,
  numberOfWords,
  setNumberOfWords,
  errorTwo,
  setErrorTwo,
  errorThree,
  setErrorThree,
  fileValue,
}) {
  // const numberword = parseInt(numberOfWords);

  const { quoteId, stepsData, setStepsData, setThirdStepData, scrollupform } =
    useContext(DataProvider);
  const location = useLocation();
  const [tailoredBtn, setTailoredBtn] = useState("");
  const handleTailoredBtn = (e) => {
    setTailoredBtn(e.target.value);
  };

  const [thankOpen, setThankOpen] = useState(false);
  const { status, mutateAsync } = useFinalQuote();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const secondStepRef = useRef(null);
  const scrollToSecond = () => {
    if (scrollupform.current) {
      scrollupform.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    scrollToSecond();
  }, [scrollupform.current]);

  const OnSubmit = (params) => {
    if (!transtalteFromInput) {
      setErrorOne(true);
      return;
    }

    const postData2 = {
      service_type: params,
      quote_id: quoteId,
      pagelink: location.href,
    };

    const postData = { ...postData2, ...formCommonPostData() };
    mutateAsync(postData).then((data) => {
      setThirdStepData(data);
      setStepsData(data);
    });
    localStorage.setItem("step", 3);
    setStep("3");
    setSelected("3");
  };

  const { status: Status, mutateAsync: MutateAsyncronous } = useTailoredQuote();
  const validateForm = () => {
    let isValid = true;

    if (!transtalteFromInput) {
      setErrorOne(true);
      isValid = false;
    } else {
      setErrorOne(false);
    }

    if (!storeValue) {
      setErrorTwo(true);
      isValid = false;
    } else {
      setErrorTwo(false);
    }

    if (!numberOfWords) {
      setErrorThree(true);
      isValid = false;
    } else {
      setErrorThree(false);
    }

    return isValid;
  };

  const SubmitSubscribe = (data) => {
    // if (!validateForm()) {
    //   scrollToSecond();
    //   return;
    // }

    const postData1 = {
      email: data.email,
      quote_id: quoteId,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    MutateAsyncronous(postData).then((response) => {
      reset();
      setThankOpen(true);
    });
  };
  return (
    <>
      <div className="flex items-center justify-center w-full ">
        <div className="mainContainer  w-full  flex-col flex-wrap justify-center font-secondary   ">
          <div className="flex-col flex items-center justify-center w-full ">
            <div className="cardsDiv lg:flex gap-2 w-full gap-y-7 justify-between py-6 ">
              <div className="cardOne w-full  lg:max:w-xl md:relative  border border-[#9AB4DB] rounded-xl  text-center  p-4 md:p-5 bg-white lg:mb-0 mb-5">
                <div className="flex justify-between gap-6">
                  <p className="text-lg  font-opensans text-[#00173A] leading-5  font-semibold ">
                    BEST PRICE
                  </p>
                  <p className="text-[26px]   font-opensans leading-6 font-bold">
                    $ 0
                  </p>
                </div>
                <div className="my-5 flex  gap-4">
                  <div className="flex items-center gap-2 font-opensans text-[#626262] font-normal text-sm">
                    <img src={Gurantee} alt="/" className="w-[13.6px]" />
                    <p className="w-[125px]">1 year guarantee</p>
                  </div>
                  <div className="flex gap-2 font-opensans text-[#626262] font-normal text-sm">
                    <img src={Pro} alt="/" />
                    <p className="w-[174px]">Professional translators</p>
                  </div>
                </div>
                <div className="border border-[#0000001a] w-full "></div>
                <div className="mt-[14px] mb-5 text-[#626262] font-opensans text-base leading-4 ">
                  <p className="flex justify-between gap-4">
                    <span className="font-medium">Total words</span>
                    <span className="text-[#323232] font-bold">-- words</span>
                  </p>

                  <p className="flex justify-between gap-4 py-[10px]">
                    <span className="font-medium">Price per word</span>
                    <span className="text-[#323232] font-bold">$0</span>
                  </p>
                  <p className="flex justify-between gap-4">
                    <span className="font-medium">Max delivery time</span>
                    <span className="text-[#323232] font-bold">0hrs</span>
                  </p>
                </div>

                <div className="">
                  <button className="best_price bg-[#ebe9e6] text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center">
                    Continue
                  </button>

                  <p className="text-[#012458] text-center pt-2 mx-auto font-opensans text-sm font-normal">
                    SAVE QUOTE
                  </p>
                </div>
              </div>
              <div className="cardOne w-full lg:max:w-xl md:relative  border border-[#9AB4DB] rounded-xl  text-center p-4 md:p-5  bg-white lg:mb-0 mb-5">
                <div className="flex justify-between gap-6">
                  <p className="text-lg  font-opensans text-[#00173A] leading-5  font-semibold ">
                    FAST DELIVERY
                  </p>
                  <p className="text-[26px]   font-opensans leading-6 font-bold">
                    $ 0
                  </p>
                </div>
                <div className="my-5 flex  gap-4">
                  <div className="flex items-center gap-2 font-opensans text-[#626262] font-normal text-sm">
                    <img src={Gurantee} alt="/" className="w-[13.6px]" />
                    <p className="w-[125px]">1 year guarantee</p>
                  </div>
                  <div className="flex gap-2 font-opensans text-[#626262] font-normal text-sm">
                    <img src={Pro} alt="/" className="w-[13.6px]" />
                    <p className="w-[172px]">Professional translators</p>
                  </div>
                </div>
                <div className="border border-[#0000001a] w-full "></div>
                <div className="mt-[14px] mb-5 text-[#626262] font-opensans text-base leading-4 font-extrabold">
                  <p className="flex justify-between gap-4">
                    <span className="font-medium">Total words</span>
                    <span className="text-[#323232] font-bold">-- words</span>
                  </p>
                  <p className="flex justify-between gap-4 py-[10px]">
                    <span className="font-medium">Price per word</span>
                    <span className="text-[#323232] font-bold">$0</span>
                  </p>
                  <p className="flex justify-between gap-4">
                    <span className="font-medium">Max delivery time</span>
                    <span className="text-[#323232] font-bold">0hrs</span>
                  </p>
                </div>

                <div className="">
                  <button className="best_price bg-[#ebe9e6] text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center">
                    Continue
                  </button>

                  <p className="text-[#012458] text-center pt-2 mx-auto font-opensans text-sm font-normal">
                    SAVE QUOTE
                  </p>
                </div>
              </div>
              <form
                className="cardThree w-full lg:max:w-xl  md:relative  border border-[#9AB4DB] rounded-xl  text-center   p-4 md:p-5  bg-white lg:mb-0 mb-5"
                onSubmit={handleSubmit(SubmitSubscribe)}
              >
                <p className="text-lg  font-opensans text-start text-[#00173A] leading-5  font-semibold ">
                GET A QUICK TAILORED QUOTE
                </p>
                <p className="pt-5 text-base text-start font-opensans text-[#626262] leading-6">
                  A Tomedes expert will analyze your request and get back to you
                  in a short time
                </p>
                <div className="my-6">
                  <input
                    className={`h-[52px]   w-full text-quotetext-200 ${"border border-quotetext-100"}  rounded-lg px-5`}
                    type="email"
                    name="email"
                    placeholder="Enter your email address here"
                    required
                    {...register("email", {
                      required: true,
                      pattern: patternEmail,
                    })}
                    onChange={handleTailoredBtn}
                    value={tailoredBtn}
                  />
                  <p className="text-left text-xs font-arial text-quotetext-200 mt-[2px] mb-1.5">
                    *will only be used to contact you once
                  </p>
                  {errors.email && (
                    <span className="text-red text-xs mt-[2px]">
                      {errorMessageEmail}
                    </span>
                  )}
                </div>
                <div className="">
                  <button
                    type="submit"
                    className={`tailored_quote bg-orange text-white rounded-3xl md:px-28 px-16 py-2 font-semibold w-full flex justify-center items-center`}
                  >
                    {Status === "loading" ? <Loader /> : "Continue"}
                  </button>

                  <p className="invisible underline max-w-max text-center pt-2 mx-auto hover:text-orange font-arial text-sm cursor-pointer">
                    SAVE QUOTE
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ThankYouPopup
          open={thankOpen}
          setOpen={setThankOpen}
          setOpenPopUp={setOpen}
        />
      </div>
    </>
  );
}

export default PricingSection;
